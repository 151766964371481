import React, { useState } from "react";
import PrimaryBttn from "../Buttons/PrimaryBttn";
import styles from "./Home.module.css";
import CardsComponent from "../Cards/CardsComponent";
import Login from "../LoginPage/Login";
import Modal from "react-responsive-modal";
function Home() {
  let cardsList = [
    {
      id:1,
      heading: "Casual Hangouts",
      text: "After a busy week, unwind with your coworkers by organizing casual get-togethers. Use our platform to plan coffee breaks, lunch outings, or happy hours, allowing everyone to relax and build friendships outside the office. Enjoy the camaraderie that leads to a happier, more cohesive work environment!",
      color: "#0E8A65",
      image: require("../../Assets/Images/card_img1.png"),
    },
    {
      id:2,
      heading: "Plan Events",
      text: "Simplify the process of planning and executing various events, from team-building activities to office parties. Our platform provides tools to create event pages, set dates and locations, manage guest lists, and send reminders, making it seamless for everyone to participate and enjoy.",
      color: "#A41726",
      image: require("../../Assets/Images/card_img2.png"),
    },
    {
      id:3,
      heading: "Hobby Circles",
      text: "Connect with colleagues who share similar interests and passions. Organize regular meetups and collaborate on projects, turning hobbies into opportunities for bonding and creativity within the workplace.",
      color: "#004545",
      image: require("../../Assets/Images/card_img3.png"),
    },
    {
      id:4,
      heading: "Sports and Fitness Activities",
      text: "Boost wellness and team spirit by organizing sports matches and fitness activities. Whether it’s cricket, soccer, or a fitness challenge, our platform makes scheduling easy. Enjoy friendly competition, strengthen relationships, and promote a healthy work-life balance.",
      color: "#115A92",
      image: require("../../Assets/Images/sports.png"),
    },
  ];

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        Hangout with your <span>Co-Employees</span> and build your{" "}
        <span>network</span>
      </div>
      <div className={styles.body}>
        Enjoy life beyond the 9-5 with your coworkers, turning casual hangouts
        into stronger bonds that lead to smoother collaboration and a more
        productive workplace.
      </div>
      <PrimaryBttn
        style={{ height: "70px", width: 250 }}
        text="Join Now"
        onClick={onOpenModal}
      />

      {/* implementing cards */}
      <div className={styles.cards}>
        {cardsList.map((card, index) => (
          <CardsComponent style={{zIndex:index, position:'sticky', top:'1em', backgroundColor:card.color}}
            key={card.id}
            image={card.image}
            heading={card.heading}
            text={card.text}
            index={index}
          />
        ))}
      </div>

      <div className={styles.body2}>
        Let’s Build Stronger Connections Together!
      </div>
      <div className={styles.body3}>
        Experience the benefits of working alongside friends. Join our community
        and see how easy it is to enjoy life beyond 9-5.
      </div>
      <PrimaryBttn
        text="Join Now"
        style={{ height: "70px", width: 300 }}
        onClick={onOpenModal}
      />
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{
          modalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          modal: {
            display: "block",
            minWidth: "60%",
            margin: "auto",
            paddingTop: 100,
            paddingBottom: 100,
            borderRadius: 10,
          },
        }}
      >
        <Login />
      </Modal>
    </div>
  );
}

export default Home;
